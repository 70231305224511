export const chapters = [
    {
        chapter: "ГЛАВА I",
        title: "ПЕРВОЕ ЗНАКОМСТВО",
        questionId: "664c8b6cee4baadac60d5208"
    },
    {
        chapter: "ГЛАВА II",
        title: "ПЕРВОЕ СВИДАНИЕ",
        questionId: "664c8b6cee4baadac60d520b"
    },
    {
        chapter: "ГЛАВА III",
        title: "ЗНАЧИМЫЕ СОБЫТИЯ",
        questionId: "664c8b6cee4baadac60d5211"
    },
    {
        chapter: "ГЛАВА IV",
        title: "ПРОЙДЕННЫЕ ИСПЫТАНИЯ",
        questionId: "664c8b6cee4baadac60d5214"
    },
    {
        chapter: "ГЛАВА V",
        title: "ПРИЧИНЫ ЛЮБИТЬ",
        questionId: "664c8b6cee4baadac60d521c"
    },
    {
        chapter: "ГЛАВА VI",
        title: "ВЗГЛЯД НА БУДУЩЕЕ",
        questionId: "664c8b6cee4baadac60d5221"
    },




    {
        chapter: "ГЛАВА I",
        title: "ПЕРВОЕ ЗНАКОМСТВО",
        questionId: "664c8b3f9947d768b3ce7d6e"
    },
    {
        chapter: "ГЛАВА II",
        title: "ПЕРВОЕ СВИДАНИЕ",
        questionId: "664c8b3f9947d768b3ce7d72"
    },
    {
        chapter: "ГЛАВА III",
        title: "ЗНАЧИМЫЕ СОБЫТИЯ",
        questionId: "664c8b3f9947d768b3ce7d77"
    },
    {
        chapter: "ГЛАВА IV",
        title: "ПРОЙДЕННЫЕ ИСПЫТАНИЯ",
        questionId: "664c8b3f9947d768b3ce7d7a"
    },
    {
        chapter: "ГЛАВА V",
        title: "ПРИЧИНЫ ЛЮБИТЬ",
        questionId: "664c8b3f9947d768b3ce7d82"
    },
    {
        chapter: "ГЛАВА VI",
        title: "ВЗГЛЯД НА БУДУЩЕЕ",
        questionId: "664c8b3f9947d768b3ce7d87"
    },
];
