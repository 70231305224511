import Navbar from "./components/Navbar";
import Login from "./components/Login";

function Home() {
  return (
    <div>
      <Navbar />
      <Login />
    </div>
  );
}

export default Home;
